var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "forum-ranking" }, [
    _c("div", { staticStyle: { width: "20%" } }, [_c("plateNavigation")], 1),
    _c(
      "div",
      { staticClass: "ranking-list" },
      _vm._l(_vm.lists, function(item, index) {
        return _c(
          "div",
          {
            key: item.id,
            staticClass: "item",
            on: {
              click: function($event) {
                return _vm.to(item.id)
              }
            }
          },
          [
            _c(
              "div",
              {
                class:
                  index === 0
                    ? "num"
                    : index === 1
                    ? "num num2"
                    : index === 2
                    ? "num num3"
                    : "num1"
              },
              [_vm._v(_vm._s(index + 1))]
            ),
            _c("div", { staticClass: "title" }, [_vm._v(_vm._s(item.title))]),
            _c("div", { staticClass: "title" }, [
              _vm._v(
                _vm._s(
                  _vm.$moment(+item.create_time).format("YYYY-MM-DD HH:mm")
                )
              )
            ])
          ]
        )
      }),
      0
    ),
    _vm._v(">\n")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }