<template>
  <div class="forum-ranking">
    <div style="width: 20%;">
      <plateNavigation />
    </div>
    <div class="ranking-list">
      <div class="item" v-for="(item, index ) in lists" :key="item.id" @click="to(item.id)">
        <div :class="index===0?'num':index===1?'num num2':index===2?'num num3':'num1'">{{index+1}}</div>
        <div class="title">{{item.title}}</div>
        <div class="title">{{ $moment(+item.create_time).format('YYYY-MM-DD HH:mm') }}</div>
      </div>
    </div>>
  </div>
</template>
<script>
export default {
  data() {
    return {
      lists: [],
    };
  },
  mounted() {
    this.getRank();
  },
  methods: {
    getRank() {
      return this.$request(Apis.forum.RTPHS)
        .then(data => {
          this.lists = data.data.data;
        })
        .catch(e => {})
        .finally(() => {});
    },
    to(id) {
      this.$router.push('/forum/details/' + id);
    },
  },
};
</script>
<style lang="less" scoped>
.forum-ranking {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  margin: 30px 0;
  .ranking-list {
    width: 75%;
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      color: #fff;
      font-size: 18px;
      margin-bottom: 20px;
      .num {
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background: red;
        font-weight: 600;
      }
      .num1 {
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        font-weight: 600;
      }
      .num2 {
        background: yellow !important;
      }
      .num3 {
        background: green !important;
      }
      .title {
        width: 40%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>